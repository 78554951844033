<template>
  <div>
    <div class="user_manage-header">
      <h2>Поддержка</h2>
      <button @click="handleAddBlock" class="btn-white--border">
        Добавить блок
      </button>
    </div>
    <template v-for="question in questions">
      <Accordeon
        :data="{
          question: question.title,
          response: question.text,
          id: question.id,
        }"
        :key="question.id"
      />
    </template>
    <template v-if="!questions.length">
      <p class="trans--all">Вопросы отсутствуют</p>
    </template>
    <div v-if="isLoading">
      <loader
        object="#fff"
        color1="#435A64"
        color2="#ECEFF0"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </div>
  </div>
</template>

<script>
import Accordeon from '@/components/ui/Accordeon';
import { mapActions, mapMutations, mapState } from 'vuex';
import { loader } from 'vue-ui-preloader';

export default {
  name: 'Help',
  mounted() {
    this.getHelp();
  },
  computed: {
    ...mapState({
      questions: state => state.questions,
      isLoading: state => state.isLoading,
    }),
  },
  methods: {
    ...mapMutations(['openModal', 'closeModal']),
    ...mapActions(['getHelp', 'createBlockQuestion']),
    handleAddBlock() {
      this.openModal({
        nameModal: 'ChangeHelpQuestions',
        dataModal: {
          callbackModal: question => {
            this.closeModal();
            this.createBlockQuestion({
              title: question.question,
              text: question.response,
              successCallback: () => {
                this.getHelp();
              },
            });
          },
        },
      });
    },
  },
  components: {
    Accordeon,
    loader,
  },
};
</script>

<style scoped>
.user_manage-header {
  margin-bottom: 48px;
}
</style>
