<template>
  <div @click="isOpen = !isOpen" class="accordeon">
    <div :class="['accordeon-header', { 'accordeon-header--open': isOpen }]">
      {{ data.question }}
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z"
          fill="#7B8C93"
        />
      </svg>
    </div>
    <div class="accordeon-content">
      {{ data.response }}
      <div class="btns-group btns-group-accordeon">
        <button @click.stop="handleDelete()">Удалить блок</button>
        <button @click.stop="handleChange()">Редактировать Вопрос</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'Accordeon',
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    data: Object,
  },
  methods: {
    ...mapMutations(['openModal', 'closeModal']),
    ...mapActions(['editBlockQuestion', 'deleteBlockQuestion', 'getHelp']),
    handleDelete() {
      this.openModal({
        nameModal: 'ConfirmDeleteBlock',
        dataModal: {
          title: this.data.question,
          callbackModal: () => {
            this.closeModal();
            this.deleteBlockQuestion({
              id: this.data.id,
              successCallback: () => {
                this.getHelp();
              },
            });
          },
        },
      });
    },
    handleChange() {
      this.openModal({
        nameModal: 'ChangeHelpQuestions',
        dataModal: {
          ...this.data,
          callbackModal: question => {
            this.closeModal();
            this.editBlockQuestion({
              title: question.question,
              text: question.response,
              id: this.data.id,
              successCallback: () => {
                this.getHelp();
              },
            });
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
